import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { Line, Html } from '@react-three/drei';
import { observer } from 'mobx-react-lite';
import { editorStore } from '../../store/editorStore';
import { floorplannerStore } from '../../store/floorplannerStore';
import { isRulerLineType, isSingleLineType, isSymbolType, isWallType, isDoubleDoorType, isDoorType, isWindowType, WallType, isCircleStairsType, isRectStairsType } from '../../types/wallTypes';
import { autorun } from 'mobx';

export const MAX_PLANE_SIZE = 300;

const SelectionPlane: React.FC = observer(() => {
    const { camera, gl } = useThree();
    const isSelecting = useRef(false);
    const startPoint = useRef<THREE.Vector3 | null>(null);
    const [endPoint, setEndPoint] = useState<THREE.Vector3 | null>(null);
    const initialMousePosition = useRef<{ x: number; y: number } | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectionRectanglePoints, setSelectionRectanglePoints] = useState<THREE.Vector3[]>([]);
    const noopRaycast = () => null;

    // Add a ref to track the current frame request
    const frameRequest = useRef<number | null>(null);

    // Add state to force updates during drag
    const [updateCounter, setUpdateCounter] = useState(0);

    // Setup autorun to track position changes
    useEffect(() => {
        const dispose = autorun(() => {
            // Track all selected objects' positions
            editorStore.selections.forEach(selection => {
                if (isSymbolType(selection)) {
                    const symbol = floorplannerStore.symbolsMap.get(selection.id);
                    if (symbol) {
                        // Access position to track changes
                        symbol.position.x;
                        symbol.position.y;
                    }
                } else if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
                    const obj = floorplannerStore.findObjectId(selection.id);
                    if (obj) {
                        // Access positions to track changes
                        obj.start.x;
                        obj.start.y;
                        obj.end.x;
                        obj.end.y;
                    }
                }
            });

            // Schedule update
            if (frameRequest.current !== null) {
                cancelAnimationFrame(frameRequest.current);
            }
            frameRequest.current = requestAnimationFrame(() => {
                setUpdateCounter(prev => prev + 1);
                frameRequest.current = null;
            });
        });

        return () => {
            dispose();
            if (frameRequest.current !== null) {
                cancelAnimationFrame(frameRequest.current);
            }
        };
    }, []);

    const handlePointerDown = (event: PointerEvent) => {
        initialMousePosition.current = { x: event.clientX, y: event.clientY };
        if (
            !editorStore.view3D &&
            !editorStore.wallConstructionMode &&
            !editorStore.lineConstructionMode &&
            !editorStore.rulerConstructionMode &&
            !editorStore.areaConstructionMode &&
            !editorStore.panning
        ) {
            isSelecting.current = true;
            startPoint.current = getWorldPositionFromMouse(event.clientX, event.clientY);
            setSelectionRectanglePoints([]);
        }
    };

    const handlePointerMove = (event: PointerEvent) => {
        // If the user is selecting and not panning, update the selection rectangle
        if (isSelecting.current && startPoint.current && !editorStore.panning && event.buttons !== 0) {
            const deltaX = Math.abs(event.clientX - initialMousePosition.current!.x);
            const deltaY = Math.abs(event.clientY - initialMousePosition.current!.y);

            if (deltaX > 2 || deltaY > 2) {
                setIsDrawing(true);
                const currentEndPoint = getWorldPositionFromMouse(event.clientX, event.clientY);

                if (currentEndPoint) {
                    setEndPoint(currentEndPoint);

                    // Create selection rectangle points directly from world positions
                    const points = [
                        startPoint.current,
                        new THREE.Vector3(currentEndPoint.x, startPoint.current.y, 0),
                        currentEndPoint,
                        new THREE.Vector3(startPoint.current.x, currentEndPoint.y, 0),
                        startPoint.current.clone()
                    ];

                    setSelectionRectanglePoints(points);
                }
            }
        }
    };

    const handlePointerUp = (event: PointerEvent) => {
        // If it is a click (no large movement), clear the selection
        if (initialMousePosition.current) {
            const deltaX = Math.abs(event.clientX - initialMousePosition.current.x);
            const deltaY = Math.abs(event.clientY - initialMousePosition.current.y);

            if (deltaX < 2 && deltaY < 2) {
                editorStore.clearSelections();
            }
        }
        // If the user was selecting, perform the selection
        if (isSelecting.current && !editorStore.panning) {
            isSelecting.current = false;

            if (isDrawing && startPoint.current && endPoint) {
                // Perform selection
                selectObjectsInBox(startPoint.current, endPoint);
            } else {
                // Clear selection on single click
                editorStore.clearSelections();
            }

            // Reset variables
            setIsDrawing(false);
            initialMousePosition.current = null;
            startPoint.current = null;
            setEndPoint(null);
            setSelectionRectanglePoints([]);
        }
    };

    const getWorldPositionFromMouse = (clientX: number, clientY: number): THREE.Vector3 | null => {
        const rect = gl.domElement.getBoundingClientRect();
        const ndcX = ((clientX - rect.left) / rect.width) * 2 - 1;
        const ndcY = -((clientY - rect.top) / rect.height) * 2 + 1;

        // Create a ray from the camera through the mouse point
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(new THREE.Vector2(ndcX, ndcY), camera);

        // Calculate the intersection with the z=0 plane
        const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1));
        const target = new THREE.Vector3();
        raycaster.ray.intersectPlane(plane, target);

        return target;
    };

    const projectToScreen = (vector: THREE.Vector3): THREE.Vector2 => {
        const projected = vector.clone().project(camera);
        const rect = gl.domElement.getBoundingClientRect();

        return new THREE.Vector2(
            (projected.x + 1) * 0.5 * rect.width + rect.left,
            (1 - projected.y) * 0.5 * rect.height + rect.top // Corrected Y calculation
        );
    };

    const selectObjectsInBox = (start: THREE.Vector3, end: THREE.Vector3) => {
        const walls = floorplannerStore.wallsMap;
        const symbols = floorplannerStore.symbolsMap;

        const screenStart = projectToScreen(start);
        const screenEnd = projectToScreen(end);

        const minX = Math.min(screenStart.x, screenEnd.x);
        const maxX = Math.max(screenStart.x, screenEnd.x);
        const minY = Math.min(screenStart.y, screenEnd.y);
        const maxY = Math.max(screenStart.y, screenEnd.y);

        // Clear previous selection
        editorStore.clearSelections();

        // Create selection rectangle in screen space
        const selectionRect = new THREE.Box2(
            new THREE.Vector2(minX, minY),
            new THREE.Vector2(maxX, maxY)
        );

        // Helper function to check if a point is inside selection rectangle
        const isPointInSelection = (point: THREE.Vector2): boolean => {
            return point.x >= minX && point.x <= maxX && point.y >= minY && point.y <= maxY;
        };

        // Helper function to check if a line segment intersects with selection rectangle
        const isLineInSelection = (start: THREE.Vector2, end: THREE.Vector2): boolean => {
            // Check if either endpoint is inside selection
            if (isPointInSelection(start) || isPointInSelection(end)) return true;

            // Check if line intersects any of the selection rectangle edges
            const rectEdges = [
                [new THREE.Vector2(minX, minY), new THREE.Vector2(maxX, minY)],
                [new THREE.Vector2(maxX, minY), new THREE.Vector2(maxX, maxY)],
                [new THREE.Vector2(maxX, maxY), new THREE.Vector2(minX, maxY)],
                [new THREE.Vector2(minX, maxY), new THREE.Vector2(minX, minY)]
            ];

            for (const [edgeStart, edgeEnd] of rectEdges) {
                if (lineSegmentsIntersect(start, end, edgeStart, edgeEnd)) {
                    return true;
                }
            }

            return false;
        };

        // Helper function to check if two line segments intersect
        const lineSegmentsIntersect = (
            line1Start: THREE.Vector2,
            line1End: THREE.Vector2,
            line2Start: THREE.Vector2,
            line2End: THREE.Vector2
        ): boolean => {
            const det = (line1End.x - line1Start.x) * (line2End.y - line2Start.y) -
                       (line2End.x - line2Start.x) * (line1End.y - line1Start.y);
            
            if (det === 0) return false;

            const lambda = ((line2End.y - line2Start.y) * (line2End.x - line1Start.x) +
                           (line2Start.x - line2End.x) * (line2End.y - line1Start.y)) / det;
            const gamma = ((line1Start.y - line1End.y) * (line2End.x - line1Start.x) +
                          (line1End.x - line1Start.x) * (line2End.y - line1Start.y)) / det;

            return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
        };

        // Process walls first
        Array.from(walls.values()).forEach((wall) => {
            const startWorld = new THREE.Vector3(wall.start.x, wall.start.y, 0);
            const endWorld = new THREE.Vector3(wall.end.x, wall.end.y, 0);
            const startScreen = projectToScreen(startWorld);
            const endScreen = projectToScreen(endWorld);

            // Check if wall line intersects with selection rectangle
            if (isLineInSelection(startScreen, endScreen)) {
                floorplannerStore.selectWall(wall.id);
                editorStore.addSelection(wall);
            }
        });

        // Process symbols
        Array.from(symbols.values()).forEach((symbol) => {
            if (!symbol) return;

            // Get the symbol's world position
            const worldPos = new THREE.Vector3(symbol.position.x, symbol.position.y, 0);
            const screenPos = projectToScreen(worldPos);

            // Calculate symbol bounds based on type and properties
            let bounds = getSymbolBounds(symbol, screenPos);

            // Check if symbol bounds intersect with selection rectangle
            if (bounds && doBoxesIntersect(selectionRect, bounds)) {
                floorplannerStore.selectSymbol(symbol.id);
                editorStore.addSelection(symbol);
            }
        });

        // Process single lines
        Array.from(floorplannerStore.singleLinesMap.values()).forEach((line) => {
            const startWorld = new THREE.Vector3(line.start.x, line.start.y, 0);
            const endWorld = new THREE.Vector3(line.end.x, line.end.y, 0);
            const startScreen = projectToScreen(startWorld);
            const endScreen = projectToScreen(endWorld);

            if (isLineInSelection(startScreen, endScreen)) {
                floorplannerStore.selectLine(line.id);
                editorStore.addSelection(line);
            }
        });

        // Process ruler lines
        Array.from(floorplannerStore.rulerLinesMap.values()).forEach((line) => {
            const startWorld = new THREE.Vector3(line.start.x, line.start.y, 0);
            const endWorld = new THREE.Vector3(line.end.x, line.end.y, 0);
            const startScreen = projectToScreen(startWorld);
            const endScreen = projectToScreen(endWorld);

            if (isLineInSelection(startScreen, endScreen)) {
                floorplannerStore.selectRuler(line.id);
                editorStore.addSelection(line);
            }
        });
    };

    // Helper function to determine door swing direction
    const swingsRight = (symbol: any): boolean => {
        if (!symbol.attachedTo) return true; // Default to right if not attached
        const wall = floorplannerStore.wallsMap.get(symbol.attachedTo);
        if (!wall) return true;

        // Calculate wall direction vector
        const wallDir = new THREE.Vector2()
            .subVectors(new THREE.Vector2(wall.end.x, wall.end.y), new THREE.Vector2(wall.start.x, wall.start.y))
            .normalize();
        
        // Calculate door position relative to wall start
        const doorPos = new THREE.Vector2()
            .subVectors(new THREE.Vector2(symbol.position.x, symbol.position.y), new THREE.Vector2(wall.start.x, wall.start.y));
        
        // Cross product to determine which side of the wall the door is on
        const cross = wallDir.x * doorPos.y - wallDir.y * doorPos.x;
        return cross > 0;
    };

    const getSymbolBounds = (symbol: any, screenPos: THREE.Vector2): THREE.Box2 | null => {
        // Get world space points based on symbol type
        let worldPoints: THREE.Vector3[] = [];

        if (isWindowType(symbol)) {
            const width = symbol.windowLength;
            const height = symbol.windowWidth;
            // Windows are centered, create points around center
            worldPoints = [
                new THREE.Vector3(-width/2, -height/2, 0),
                new THREE.Vector3(width/2, -height/2, 0),
                new THREE.Vector3(width/2, height/2, 0),
                new THREE.Vector3(-width/2, height/2, 0)
            ];
        } else if (isDoorType(symbol)) {
            const width = symbol.doorWidth;
            const height = width; // Height equals width for doors
            
            // Door frame points only (from bottom-left origin)
            worldPoints = [
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(width, 0, 0),
                new THREE.Vector3(width, height, 0),
                new THREE.Vector3(0, height, 0)
            ];
        } else if (isDoubleDoorType(symbol)) {
            const leafWidth = symbol.doubleDoorWidth; // Width of a single leaf
            const height = leafWidth;
            
            // Door frame points only
            worldPoints = [
                new THREE.Vector3(0, 0, 0),
                new THREE.Vector3(leafWidth * 2, 0, 0),
                new THREE.Vector3(leafWidth * 2, height, 0),
                new THREE.Vector3(0, height, 0)
            ];
        } else if (isCircleStairsType(symbol)) {
            // Calculate points along the arc at small intervals
            const numPoints = 36;  // Check every 10 degrees
            const flipX = symbol.flipHorizontal ? -1 : 1;
            const flipY = symbol.flipVertical ? -1 : 1;
            
            // Always include the starting point (right side, 0 degrees)
            worldPoints.push(new THREE.Vector3(
                symbol.position.x + symbol.circleStairsWidth * flipX,
                symbol.position.y,
                0
            ));

            // Sample points along the arc
            for (let i = 0; i <= numPoints; i++) {
                const angle = (i / numPoints) * symbol.openAngle;
                const x = symbol.position.x + symbol.circleStairsWidth * Math.cos(angle) * flipX;
                const y = symbol.position.y + symbol.circleStairsWidth * Math.sin(angle) * flipY;
                worldPoints.push(new THREE.Vector3(x, y, 0));
            }
        } else if (isRectStairsType(symbol)) {
            const width = symbol.rectStairsWidth;
            const height = symbol.rectStairsHeight;
            const flipX = symbol.flipHorizontal ? -1 : 1;
            const flipY = symbol.flipVertical ? -1 : 1;
            
            // Create points for the rectangular stairs
            worldPoints = [
                new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0),
                new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0)  // Close the loop
            ];
            
            // Move points to world position before rotation
            worldPoints.forEach(point => {
                point.add(new THREE.Vector3(symbol.position.x, symbol.position.y, 0));
            });

            // Apply rotation if present
            if (symbol.rotation !== undefined) {
                const rotationPoint = new THREE.Vector3(symbol.position.x, symbol.position.y, 0);
                worldPoints.forEach(point => {
                    point.sub(rotationPoint);
                    point.applyAxisAngle(new THREE.Vector3(0, 0, 1), symbol.rotation || 0);
                    point.add(rotationPoint);
                });
            }

            // Skip the general rotation and position application since we've already done it
            return createBoundsFromPoints(worldPoints.map(p => projectToScreen(p)));
        } else {
            // Handle specific symbol types with their dimensions
            let width = 1;
            let height = 1;
            let flipX = 1;
            let flipY = 1;

            if ('squareWidth' in symbol) {
                width = symbol.squareWidth;
                height = symbol.squareHeight;
                flipX = symbol.flipHorizontal ? -1 : 1;
                flipY = symbol.flipVertical ? -1 : 1;
            } else if ('triangleWidth' in symbol) {
                width = symbol.triangleWidth;
                height = symbol.triangleHeight;
                flipX = symbol.flipHorizontal ? -1 : 1;
                flipY = symbol.flipVertical ? -1 : 1;
            } else if ('circleWidth' in symbol) {
                width = symbol.circleWidth;
                height = symbol.circleHeight;
                flipX = symbol.flipHorizontal ? -1 : 1;
                flipY = symbol.flipVertical ? -1 : 1;
            } else {
                width = (symbol as any)?.width ?? 1;
                height = (symbol as any)?.height ?? 1;
            }

            worldPoints = [
                new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0)
            ];
        }

        // Apply rotation if present
        if (symbol.rotation !== undefined) {
            const rotationMatrix = new THREE.Matrix4();
            const rotationPoint = new THREE.Vector3(symbol.position.x, symbol.position.y, 0);
            
            if (isRectStairsType(symbol)) {
                // Rect stairs rotate around center
                worldPoints.forEach(point => {
                    point.sub(rotationPoint);
                    point.applyAxisAngle(new THREE.Vector3(0, 0, 1), symbol.rotation || 0);
                    point.add(rotationPoint);
                });
            } else if (isWindowType(symbol) || !isDoorType(symbol)) {
                // Windows and other symbols rotate around center
                rotationMatrix.makeRotationZ(symbol.rotation || 0);
                worldPoints.forEach(point => point.applyMatrix4(rotationMatrix));
            } else {
                // Doors rotate around bottom-left
                rotationMatrix.makeRotationZ(symbol.rotation || 0);
                worldPoints.forEach(point => point.applyMatrix4(rotationMatrix));
            }
        }

        // Move to world position (skip for circle stairs and rect stairs as we already included position)
        if (!isCircleStairsType(symbol) && !isRectStairsType(symbol)) {
            worldPoints.forEach(point => {
                point.add(new THREE.Vector3(symbol.position.x, symbol.position.y, 0));
            });
        }

        // Project to screen space
        const screenPoints = worldPoints.map(p => projectToScreen(p));

        return createBoundsFromPoints(screenPoints);
    };

    // Helper function to create bounds from points
    const createBoundsFromPoints = (points: THREE.Vector2[]): THREE.Box2 => {
        const bounds = new THREE.Box2();
        points.forEach(p => bounds.expandByPoint(p));
        return bounds;
    };

    // Helper function to check if two boxes intersect
    const doBoxesIntersect = (box1: THREE.Box2, box2: THREE.Box2): boolean => {
        return !(
            box1.max.x < box2.min.x ||
            box1.min.x > box2.max.x ||
            box1.max.y < box2.min.y ||
            box1.min.y > box2.max.y
        );
    };

    useEffect(() => {
        if (editorStore.selections.length === 0) {
            setSelectionRectanglePoints([]);
        }
    }, [editorStore.selections]);

    // Memoize the bounding box calculation to prevent unnecessary recalculations
    const boundingBoxPoints = useMemo(() => {
        // Only show bounding box when multiple items are selected
        if (editorStore.selections.length <= 1) return [];

        // If we're drawing the selection rectangle, don't show the bounding box
        if (isDrawing) return [];

        // Calculate bounds in world space
        let minX = Infinity, minY = Infinity;
        let maxX = -Infinity, maxY = -Infinity;

        editorStore.selections.forEach((object) => {
            if (isSymbolType(object)) {
                const symbol = floorplannerStore.symbolsMap.get(object.id);
                if (!symbol) return;

                // Get world space points based on symbol type
                let points: THREE.Vector3[] = [];
                if (isDoubleDoorType(symbol)) {
                    const width = symbol.doubleDoorWidth * 2;
                    const height = symbol.doubleDoorWidth;
                    points = [
                        new THREE.Vector3(0, 0, 0),
                        new THREE.Vector3(width, 0, 0),
                        new THREE.Vector3(width, height, 0),
                        new THREE.Vector3(0, height, 0)
                    ];
                } else if (isDoorType(symbol)) {
                    const width = symbol.doorWidth;
                    const height = width;
                    points = [
                        new THREE.Vector3(0, 0, 0),
                        new THREE.Vector3(width, 0, 0),
                        new THREE.Vector3(width, height, 0),
                        new THREE.Vector3(0, height, 0)
                    ];
                } else if (isWindowType(symbol)) {
                    const width = symbol.windowLength;
                    const height = symbol.windowWidth;
                    points = [
                        new THREE.Vector3(-width/2, -height/2, 0),
                        new THREE.Vector3(width/2, -height/2, 0),
                        new THREE.Vector3(width/2, height/2, 0),
                        new THREE.Vector3(-width/2, height/2, 0)
                    ];
                } else if (isRectStairsType(symbol)) {
                    const width = symbol.rectStairsWidth;
                    const height = symbol.rectStairsHeight;
                    const flipX = symbol.flipHorizontal ? -1 : 1;
                    const flipY = symbol.flipVertical ? -1 : 1;
                    
                    points = [
                        new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                        new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0)
                    ];
                } else if ('squareWidth' in symbol && 'squareHeight' in symbol) {
                    const width = Number(symbol.squareWidth);
                    const height = Number(symbol.squareHeight);
                    const flipX = symbol.flipHorizontal ? -1 : 1;
                    const flipY = symbol.flipVertical ? -1 : 1;
                    points = [
                        new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                        new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0)
                    ];
                } else if ('triangleWidth' in symbol && 'triangleHeight' in symbol) {
                    const width = Number(symbol.triangleWidth);
                    const height = Number(symbol.triangleHeight);
                    const flipX = symbol.flipHorizontal ? -1 : 1;
                    const flipY = symbol.flipVertical ? -1 : 1;
                    points = [
                        new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                        new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0)
                    ];
                } else if ('circleWidth' in symbol && 'circleHeight' in symbol) {
                    const width = Number(symbol.circleWidth);
                    const height = Number(symbol.circleHeight);
                    const flipX = symbol.flipHorizontal ? -1 : 1;
                    const flipY = symbol.flipVertical ? -1 : 1;
                    points = [
                        new THREE.Vector3(-width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, -height/2 * flipY, 0),
                        new THREE.Vector3(width/2 * flipX, height/2 * flipY, 0),
                        new THREE.Vector3(-width/2 * flipX, height/2 * flipY, 0)
                    ];
                } else {
                    const width = (symbol as any)?.width ?? 1;
                    const height = (symbol as any)?.height ?? 1;
                    points = [
                        new THREE.Vector3(-width/2, -height/2, 0),
                        new THREE.Vector3(width/2, -height/2, 0),
                        new THREE.Vector3(width/2, height/2, 0),
                        new THREE.Vector3(-width/2, height/2, 0)
                    ];
                }

                // Apply rotation if present
                if (symbol.rotation !== undefined) {
                    const rotationPoint = new THREE.Vector3();
                    if (isWindowType(symbol) || isRectStairsType(symbol) || (!isDoorType(symbol) && !isDoubleDoorType(symbol))) {
                        // Windows, rect stairs, and other symbols rotate around center
                        points.forEach(point => {
                            point.applyAxisAngle(new THREE.Vector3(0, 0, 1), symbol.rotation || 0);
                        });
                    } else {
                        // Doors rotate around bottom-left corner
                        points.forEach(point => {
                            point.applyAxisAngle(new THREE.Vector3(0, 0, 1), symbol.rotation || 0);
                        });
                    }
                }

                // Move to world position
                points.forEach(point => {
                    point.add(new THREE.Vector3(symbol.position.x, symbol.position.y, 0));
                    minX = Math.min(minX, point.x);
                    maxX = Math.max(maxX, point.x);
                    minY = Math.min(minY, point.y);
                    maxY = Math.max(maxY, point.y);
                });
            } else if (isWallType(object) || isSingleLineType(object) || isRulerLineType(object)) {
                const obj = floorplannerStore.findObjectId(object.id);
                if (!obj) return;

                minX = Math.min(minX, obj.start.x, obj.end.x);
                maxX = Math.max(maxX, obj.start.x, obj.end.x);
                minY = Math.min(minY, obj.start.y, obj.end.y);
                maxY = Math.max(maxY, obj.start.y, obj.end.y);

                if (isWallType(object)) {
                    const wall = obj as WallType;
                    const wallWidth = wall.wallWidth || floorplannerStore.wallWidth;
                    const dx = obj.end.x - obj.start.x;
                    const dy = obj.end.y - obj.start.y;
                    const length = Math.sqrt(dx * dx + dy * dy);
                    if (length > 0) {
                        const nx = (-dy / length) * wallWidth / 2;
                        const ny = (dx / length) * wallWidth / 2;
                        const points = [
                            new THREE.Vector3(obj.start.x + nx, obj.start.y + ny, 0),
                            new THREE.Vector3(obj.start.x - nx, obj.start.y - ny, 0),
                            new THREE.Vector3(obj.end.x + nx, obj.end.y + ny, 0),
                            new THREE.Vector3(obj.end.x - nx, obj.end.y - ny, 0)
                        ];
                        points.forEach(point => {
                            minX = Math.min(minX, point.x);
                            maxX = Math.max(maxX, point.x);
                            minY = Math.min(minY, point.y);
                            maxY = Math.max(maxY, point.y);
                        });
                    }
                }
            }
        });

        if (minX === Infinity) return [];

        // Add small padding in world space
        const padding = 0.1;
        minX -= padding;
        minY -= padding;
        maxX += padding;
        maxY += padding;

        // Create corners in world space
        return [
            new THREE.Vector3(minX, minY, 0),
            new THREE.Vector3(maxX, minY, 0),
            new THREE.Vector3(maxX, maxY, 0),
            new THREE.Vector3(minX, maxY, 0),
            new THREE.Vector3(minX, minY, 0)
        ];
    }, [editorStore.selections, updateCounter, selectionRectanglePoints]);

    return (
        <>
            <mesh
                position={[0, 0, -0.01]} // Ensure the plane is in the background
                onPointerDown={(e) => handlePointerDown(e.nativeEvent)}
                onPointerMove={(e) => handlePointerMove(e.nativeEvent)}
                onPointerUp={(e) => handlePointerUp(e.nativeEvent)}
                userData={{ id: 'selection-plane' }}
            >
                <planeGeometry args={[MAX_PLANE_SIZE * 1.5, MAX_PLANE_SIZE * 1.5]} />
                <meshBasicMaterial transparent opacity={0} />
            </mesh>

            {/* Render transparent blue selection box while drawing */}
            {isDrawing && selectionRectanglePoints.length > 0 && (
                <mesh
                    position={[
                        (selectionRectanglePoints[0].x + selectionRectanglePoints[2].x) / 2,
                        (selectionRectanglePoints[0].y + selectionRectanglePoints[2].y) / 2,
                        0.3, // Above everything
                    ]}
                    geometry={new THREE.PlaneGeometry(
                        Math.abs(selectionRectanglePoints[2].x - selectionRectanglePoints[0].x),
                        Math.abs(selectionRectanglePoints[2].y - selectionRectanglePoints[0].y)
                    )}
                >
                    <meshBasicMaterial color="blue" transparent opacity={0.2} />
                </mesh>
            )}

            {/* Render the selection rectangle outline while drawing */}
            {isDrawing && selectionRectanglePoints.length > 0 && (
                <Line
                    position={[0, 0, 0.3]} // Above everything
                    points={selectionRectanglePoints}
                    color="darkblue"
                    lineWidth={1}
                    raycast={noopRaycast}
                />
            )}

            {/* Render the final bounding box around selected items */}
            {boundingBoxPoints.length > 0 && (
                <group position={[0, 0, 0.02]}>
                    <Line
                        points={boundingBoxPoints}
                        color="#0066FF"
                        lineWidth={1}
                        transparent={false}
                        depthTest={false}
                        renderOrder={9999}
                        raycast={noopRaycast}
                    />
                </group>
            )}
        </>
    );
});

export default SelectionPlane;
