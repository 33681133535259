import { action, computed, makeAutoObservable, observable, transaction } from "mobx";
import React, { useRef } from "react";
import { useContext } from "react";
import { 
  WallType, SymbolType, isSymbolType, SingleLineType, isSingleLineType, 
  isWallType, RulerLineType, isRulerLineType, WallConnectionType,
  DoorType, WindowType, TextType, SquareSymbolType, CircleSymbolType,
  TriangleSymbolType, isDoorType, isWindowType, isTextType,
  isSquareSymbolType, isCircleSymbolType, isTriangleSymbolType,
  CircleStairsType, RectStairsType, SvgType, isCircleStairsType,
  isRectStairsType, isSvgType, isDoubleDoorType
} from '../types/wallTypes';
import { floorplannerStore } from "./floorplannerStore";
import { ApolloClient } from "@apollo/client";
import * as THREE from "three";
import { invalidate } from "@react-three/fiber";
import { symbol } from 'prop-types';
import * as polygonClipping from 'polygon-clipping';
import jsPDF from "jspdf";
import { renderStore } from "./renderStore";
import { updateWallConnections } from "../components/FloorPlan/updateWallConnections";
import { reaction } from "mobx";

// Add these type definitions before the EditorStore class
interface ClipboardWall extends WallType {
  originalId: string;
  selectedConnections?: WallConnectionType[];
}

type ClipboardSymbol = (SymbolType | DoorType | WindowType | TextType | 
  SquareSymbolType | CircleSymbolType | TriangleSymbolType) & {
  originalId: string;
  attachedToOriginal?: string;
};

interface ClipboardSingleLine extends SingleLineType {
  originalId: string;
}

interface ClipboardRulerLine extends RulerLineType {
  originalId: string;
}

type ClipboardItem = ClipboardWall | ClipboardSymbol | ClipboardSingleLine | ClipboardRulerLine;

class EditorStore {
  client: ApolloClient<any> | undefined;
  floorplannerStore = floorplannerStore;
  view3D = false;
  selections = Array<WallType | SymbolType | SingleLineType | RulerLineType>();
  wallEditingOuterLength = undefined as string | undefined;
  wallEditingInnerLength = undefined as string | undefined;
  wallEditingLengthPanel = undefined as number | undefined;
  singleLineEditingLength = undefined as string | undefined;
  singleLineEditingLengthPanel = undefined as number | undefined;
  rulerLineEditingLength = undefined as string | undefined;
  areaEditingLength = undefined as string | undefined;
  rulerEditingLengthPanel = undefined as number | undefined;
  textEditing = undefined as string | undefined;
  showMeasures = true;
  textShow = true;
  showGrid = true;
  minimized = false;
  @computed get canUndo() {
    return this.floorplannerStore.undoStack.length > 0;
  }
  @computed get canRedo() {
    return this.floorplannerStore.redoStack.length > 0;
  }
  clipboard: ClipboardItem[] = [];
  pasteOffset = new THREE.Vector2(0.2, 0.2);
  lastPastePosition: THREE.Vector2 | null = null;
  // Debug flag to visualize bounds during paste operation
  debugPasteVisualization = false;
  @observable.ref camera: THREE.PerspectiveCamera | THREE.OrthographicCamera | null = null;
  @observable zoomLevel = 1;
  wallConstructionMode = false;
  wallConstructionModeAddNewWall = false;
  lineConstructionMode = false;
  lineConstructionModeAddNewLine = false;
  rulerConstructionMode = false;
  rulerConstructionModeAddNewLine = false;
  areaConstructionMode = false;
  areaConstructionModeAddPoint = false;
  movementOffsetFine = 0.001;
  movementOffsetCorse = 0.01;
  panning = false;
  previousPanningState: boolean | null = null;
  groupRefsMap = new Map<string, THREE.Group | null>();
  isShiftPressed = false;
  wallDragging: string | null = null;
  lineDragging: string | null = null;
  rulerDragging: string | null = null;
  snappingTolerance = 0.1;
  whichEndToUpdate: "start" | "end" | undefined = undefined;
  // Download project
  downloadProjectVisible = false;
  downloadProjectPosition = { top: 0, left: 0 };
  // Add to presentation
  addToPresentationVisible = false;
  addToPresentationPosition = { top: 0, left: 0 };
  
  constructor() {
    makeAutoObservable(this, {
      camera: observable.ref,
      zoomLevel: observable,
    });
    document.addEventListener("keydown", this.handleKeyDown, true);
    document.addEventListener("keyup", this.handleKeyUp, true);
    document.addEventListener("mousedown", this.handleMouseDown, true);
    document.addEventListener("mouseup", this.handleMouseUp, true);

    // Add reaction to camera changes
    reaction(
      () => ({
        camera: this.camera,
        zoom: this.zoomLevel,
        position: this.camera ? [this.camera.position.x, this.camera.position.y, this.camera.position.z] : null
      }),
      () => {
        // Update view bounds visualization whenever camera changes
        if (this.debugPasteVisualization && renderStore.scene) {
          // Clear existing debug lines
          renderStore.scene.children = renderStore.scene.children.filter(
            child => !(child instanceof THREE.Line && child.userData.isDebugLine)
          );
          
          // Redraw view bounds
          const visibleBounds = this.getVisibleBounds();
          if (visibleBounds) {
            const viewBoundsLine = this.createDebugLine(
              [
                visibleBounds.xMin, visibleBounds.yMin, 0,
                visibleBounds.xMax, visibleBounds.yMin, 0,
                visibleBounds.xMax, visibleBounds.yMax, 0,
                visibleBounds.xMin, visibleBounds.yMax, 0,
                visibleBounds.xMin, visibleBounds.yMin, 0
              ],
              0x000080, // Dark blue color
              1.0, // Full opacity
              3 // Thicker line
            );
            viewBoundsLine.userData.isDebugLine = true;
            renderStore.scene.add(viewBoundsLine);
          }
          invalidate();
        }
      }
    );
  }

  dispose() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("mousedown", this.handleMouseDown);
    document.removeEventListener("mouseup", this.handleMouseUp);
  }

  initialize(client: ApolloClient<any>) {
    this.client = client;
  }

  componentDidMount() {
    window.focus();
  }

  setCamera(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
    this.camera = camera;
    // Sync the zoom level when setting the camera
    if (camera) {
      this.setZoomLevel(camera.zoom);
    }
  }

  @action
  setZoomLevel = (level: number) => {
    this.zoomLevel = level;
    if (this.camera) {
      this.camera.zoom = level;
      this.camera.updateProjectionMatrix();
    }
  }

  zoomIn = () => {
    if (this.camera) {
      const newZoom = Math.min(this.zoomLevel + 0.05, 7);
      this.setZoomLevel(newZoom);
      invalidate();
    }
  };

  zoomOut = () => {
    if (this.camera && this.camera.zoom > 0.1) {
      const newZoom = Math.max(this.zoomLevel - 0.05, 0.2);
      this.setZoomLevel(newZoom);
      invalidate();
    }
  };

  resetZoom = () => {
    if (this.camera) {
      this.setZoomLevel(1);
      // Reset position
      if (renderStore.scene) {
        const box = renderStore.computeBoundingBoxForScene(renderStore.scene);
        const center = box.getCenter(new THREE.Vector3());
        this.camera.position.set(center.x, center.y, 20);
      } else {
        this.camera.position.set(0, 0, 20);
      }
      invalidate();
    }
  };

  zoomLevelDivisor = () => {
    // Return the divisor
    return this.zoomLevel / 1.4;
  }

  @action setPanning = (panning: boolean) => {
    this.panning = panning;
  }

  @action setShiftPressed = (pressed: boolean) => {
    this.isShiftPressed = pressed;
  }

  // Undo action
  undo = () => {
    this.floorplannerStore.undo();
  };

  // Redo action
  redo = () => {
    this.floorplannerStore.redo();
  };

  copy = () => {
    // Create a deep copy of selected elements with additional metadata
    this.clipboard = this.selections.map(selection => {
      // Create a proper deep copy of the selection
      const copy = JSON.parse(JSON.stringify({
        ...selection,
        originalId: selection.id,
        position: selection.type === "wall" || selection.type === "singleLine" || selection.type === "rulerLine"
          ? { start: selection.start, end: selection.end }
          : { x: selection.position.x, y: selection.position.y }
      }));

      // Restore Vector2 instances
      if (copy.type === "wall" || copy.type === "singleLine" || copy.type === "rulerLine") {
        copy.start = new THREE.Vector2(copy.position.start.x, copy.position.start.y);
        copy.end = new THREE.Vector2(copy.position.end.x, copy.position.end.y);
      } else {
        copy.position = new THREE.Vector2(copy.position.x, copy.position.y);
      }
      
      // For walls, store connected walls that are also selected
      if (isWallType(selection)) {
        (copy as ClipboardWall).selectedConnections = selection.connections?.filter(conn => 
          this.selections.some(sel => sel.id === conn.id)
        );
      }
      
      // For symbols, store wall attachment if the wall it's attached to is also selected
      if (isSymbolType(selection)) {
        const symbol = this.floorplannerStore.symbolsMap.get(selection.id);
        if (symbol && symbol.attachedTo) {
          // Only store the attachment if the wall it's attached to is also selected
          if (this.selections.some(sel => sel.id === symbol.attachedTo)) {
            copy.attachedToOriginal = symbol.attachedTo;
          }
        }
      }
      
      return copy;
    });
    
    // Reset lastPastePosition when copying new items
    this.lastPastePosition = null;
  };

  paste = () => {
    if (this.clipboard.length === 0) return;

    // Push to undo stack once before any changes
    floorplannerStore.pushToUndoStack();
    
    // Block dirty state updates during the paste operation
    floorplannerStore.setBlockDirty(true);

    try {
      // Calculate bounding box of clipboard items
      const clipboardBounds = this.calculateClipboardBounds(this.clipboard);
      
      // Find an open area for pasting
      const openPosition = this.findOpenArea(clipboardBounds);
      
      // Calculate offset from original center to new center
      const clipboardCenter = new THREE.Vector2(
        (clipboardBounds.min.x + clipboardBounds.max.x) / 2,
        (clipboardBounds.min.y + clipboardBounds.max.y) / 2
      );
      const pasteOffset = openPosition.clone().sub(clipboardCenter);

      // Create a map to store original ID to new ID mappings
      const idMap = new Map<string, string>();
      
      // Keep track of newly created items for selection
      const newItems: Array<WallType | SymbolType | SingleLineType | RulerLineType> = [];

      transaction(() => {
        // First pass: Create all walls first
        this.clipboard.forEach(item => {
          if (isWallType(item)) {
            // Apply offset to both start and end points
            const newStart = item.start.clone().add(pasteOffset);
            const newEnd = item.end.clone().add(pasteOffset);
            
            const newWall = {
              ...item,
              id: Math.random().toString(36).substr(2, 9),
              start: newStart,
              end: newEnd,
              connections: [], // Will be restored in second pass
              symbolAttachments: [], // Will be restored in second pass
            };
            
            floorplannerStore.wallsMap.set(newWall.id, newWall);
            idMap.set(item.originalId, newWall.id);
            newItems.push(newWall);
          }
        });

        // Second pass: Create all other objects
        this.clipboard.forEach(item => {
          if (!isWallType(item)) {
            let newId = '';
            let newItem: SymbolType | SingleLineType | RulerLineType | undefined;

            if (isSingleLineType(item) || isRulerLineType(item)) {
              // Apply offset to both start and end points
              const newStart = item.start.clone().add(pasteOffset);
              const newEnd = item.end.clone().add(pasteOffset);
              
              if (isSingleLineType(item)) {
                const newLine: SingleLineType = {
                ...item,
                id: Math.random().toString(36).substr(2, 9),
                start: newStart,
                end: newEnd,
                connections: [],
                  type: "singleLine"
              };
              floorplannerStore.singleLinesMap.set(newLine.id, newLine);
              newId = newLine.id;
              newItem = newLine;
              } else {
                const newLine: RulerLineType = {
                ...item,
                id: Math.random().toString(36).substr(2, 9),
                start: newStart,
                end: newEnd,
                connections: [],
                  type: "rulerLine"
              };
              floorplannerStore.rulerLinesMap.set(newLine.id, newLine);
              newId = newLine.id;
              newItem = newLine;
              }
            } else if (isSymbolType(item)) {
              // Apply offset to position
              const newPosition = item.position.clone().add(pasteOffset);
              
              // Create symbol without any attachment
              const { originalId, attachedToOriginal, position, attachedTo, ...symbolProps } = item;
              const newSymbol = {
                ...symbolProps,
                id: Math.random().toString(36).substr(2, 9),
                position: newPosition,
                attachedTo: undefined,
              };
              
              floorplannerStore.symbolsMap.set(newSymbol.id, newSymbol);
              newId = newSymbol.id;
              newItem = newSymbol;
            }

            // Store the mapping between original and new IDs
            idMap.set(item.originalId, newId);
            
            // Add to new items array if item was created
            if (newItem) {
              newItems.push(newItem);
            }
          }
        });

        // After all symbols are created, preserve their relative z-index ordering
        const symbolsToPaste = this.clipboard.filter((item): item is ClipboardSymbol => isSymbolType(item));
        if (symbolsToPaste.length > 0) {
          // Find the maximum z-index in the current symbolsMap
          const maxZIndex = Math.max(...Array.from(floorplannerStore.symbolsMap.values()).map(s => s.zIndex));
          
          // Sort symbols by their original z-index
          symbolsToPaste.sort((a, b) => a.zIndex - b.zIndex);
          
          // Assign new z-indexes while preserving relative order
          symbolsToPaste.forEach((symbol, index) => {
            const newId = idMap.get(symbol.originalId);
            if (newId) {
              const newSymbol = floorplannerStore.symbolsMap.get(newId);
              if (newSymbol) {
                newSymbol.zIndex = maxZIndex + 1 + index;
              }
            }
          });
        }

        // Third pass: Restore connections and attachments
        this.clipboard.forEach(item => {
          if (isWallType(item) && (item as ClipboardWall).selectedConnections) {
            const newWallId = idMap.get(item.originalId);
            if (!newWallId) return;

            (item as ClipboardWall).selectedConnections?.forEach(conn => {
              const newConnectedId = idMap.get(conn.id);
              if (!newConnectedId) return;

              const sourceWall = floorplannerStore.wallsMap.get(newWallId);
              const targetWall = floorplannerStore.wallsMap.get(newConnectedId);
              
              if (sourceWall && targetWall) {
                if (!sourceWall.connections) sourceWall.connections = [];
                if (!targetWall.connections) targetWall.connections = [];
                
                sourceWall.connections.push({
                  id: newConnectedId,
                  sourcePosition: conn.sourcePosition,
                  targetPosition: conn.targetPosition,
                });
                
                targetWall.connections.push({
                  id: newWallId,
                  sourcePosition: conn.targetPosition,
                  targetPosition: conn.sourcePosition,
                });
              }
            });
          }

          if (isSymbolType(item)) {
            const attachedToOriginal = (item as ClipboardSymbol).attachedToOriginal;
            if (attachedToOriginal) {
              const newSymbolId = idMap.get(item.originalId);
              const newWallId = idMap.get(attachedToOriginal);
                            
              if (!newSymbolId || !newWallId) {
                console.error('Failed to find new IDs for attachment restoration');
                return;
              }

              const symbol = floorplannerStore.symbolsMap.get(newSymbolId);
              const wall = floorplannerStore.wallsMap.get(newWallId);
              
              if (!symbol || !wall) {
                console.error('Failed to find symbol or wall for attachment');
                return;
              }

              // Update the symbol's attachment
              symbol.attachedTo = newWallId;
              
              // Update the wall's symbol attachments
              if (!wall.symbolAttachments) wall.symbolAttachments = [];
              
              // Calculate position from start as a ratio along the wall
              const wallDirection = wall.end.clone().sub(wall.start);
              const symbolOffset = symbol.position.clone().sub(wall.start);
              const positionFromStart = symbolOffset.dot(wallDirection.normalize());
              
              wall.symbolAttachments.push({
                symbolId: newSymbolId,
                positionFromStart,
              });
            }
          }
        });

        // Clear current selections and select all newly created items
        this.clearSelections();
        newItems.forEach(item => {
          if (isWallType(item)) {
            floorplannerStore.selectWall(item.id);
          } else if (isSymbolType(item)) {
            floorplannerStore.selectSymbol(item.id);
          } else if (isSingleLineType(item)) {
            floorplannerStore.selectLine(item.id);
          } else if (isRulerLineType(item)) {
            floorplannerStore.selectRuler(item.id);
          }
          this.addSelection(item);
        });

        // Re-attach symbols to walls to ensure proper rendering
        newItems.forEach(item => {
          if (isSymbolType(item)) {
            const symbol = floorplannerStore.symbolsMap.get(item.id);
            if (symbol && symbol.attachedTo) {
              const wall = floorplannerStore.wallsMap.get(symbol.attachedTo);
              if (wall) {
                // Temporarily detach and re-attach to force update
                const currentPosition = symbol.position.clone();
                floorplannerStore.detachSymbolFromWall(item.id);
                floorplannerStore.attachSymbolToWall(item.id, wall.id, [currentPosition.x, currentPosition.y]);
              }
            }
          }
        });

        // Force update all pasted walls to ensure proper rendering
        this.clipboard.forEach(item => {
          if (isWallType(item)) {
            const newWallId = idMap.get(item.originalId);
            if (newWallId) {
              floorplannerStore.objectNeedsUpdate(newWallId);
            }
          }
        });

        // Calculate bounds of pasted items
        const pastedBounds = {
          xMin: Infinity,
          xMax: -Infinity,
          yMin: Infinity,
          yMax: -Infinity
        };

        // Calculate bounds for all pasted items
        newItems.forEach(item => {
          if (isWallType(item) || isSingleLineType(item) || isRulerLineType(item)) {
            pastedBounds.xMin = Math.min(pastedBounds.xMin, item.start.x, item.end.x);
            pastedBounds.xMax = Math.max(pastedBounds.xMax, item.start.x, item.end.x);
            pastedBounds.yMin = Math.min(pastedBounds.yMin, item.start.y, item.end.y);
            pastedBounds.yMax = Math.max(pastedBounds.yMax, item.start.y, item.end.y);
          } else if (isSymbolType(item)) {
            const symbol = floorplannerStore.symbolsMap.get(item.id);
            if (symbol) {
              const dimensions = this.getSymbolDimensions(symbol);
              const halfWidth = dimensions.width / 2;
              const halfHeight = dimensions.height / 2;
              
              pastedBounds.xMin = Math.min(pastedBounds.xMin, symbol.position.x - halfWidth);
              pastedBounds.xMax = Math.max(pastedBounds.xMax, symbol.position.x + halfWidth);
              pastedBounds.yMin = Math.min(pastedBounds.yMin, symbol.position.y - halfHeight);
              pastedBounds.yMax = Math.max(pastedBounds.yMax, symbol.position.y + halfHeight);
            }
          }
        });

        // Only center camera if pasted items are not fully within current view bounds
        if (this.camera && 
            pastedBounds.xMin !== Infinity && 
            pastedBounds.xMax !== -Infinity && 
            pastedBounds.yMin !== Infinity && 
            pastedBounds.yMax !== -Infinity) {
          
          const visibleBounds = this.getVisibleBounds();
          if (visibleBounds) {
            // Check if pasted items are outside current view
            const isOutsideView = 
              pastedBounds.xMin < visibleBounds.xMin ||
              pastedBounds.xMax > visibleBounds.xMax ||
              pastedBounds.yMin < visibleBounds.yMin ||
              pastedBounds.yMax > visibleBounds.yMax;

            // Only center camera if items are outside current view
            if (isOutsideView) {
              const centerX = (pastedBounds.xMin + pastedBounds.xMax) / 2;
              const centerY = (pastedBounds.yMin + pastedBounds.yMax) / 2;
              this.camera.position.x = centerX;
              this.camera.position.y = centerY;
              invalidate();
            }
          }
        }
      });
    } finally {
      // Re-enable dirty state updates after paste operation
      floorplannerStore.setBlockDirty(false);
      floorplannerStore.setDirty();
      invalidate(); // Force a re-render
    }
  };

  duplicateSelected = () => {
    this.copy();
    this.paste();
  }

  bringToFront = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallToFront(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToFront(selection.id);
      }
    });
  }

  sendToBack = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallToBack(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.sendToBack(selection.id);
      }
    });
  }

  bringForward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.bringWallForward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringForward(selection.id);
      }
    });
  }

  bringBackward = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        //this.floorplannerStore.sendWallBackward(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.bringBackward(selection.id);
      }
    });
  }
  /*   addText = () => {
      this.selections.forEach((selection) => {
          if (selection.type === "text") {
            floorplannerStore.addSymbol("text", [0, 0]);
          } 
      });
    }
       */


  // Method to get the visible bounds based on the current camera position and zoom level
  getVisibleBounds = () => {
    if (!this.camera) return null;

    const aspectRatio = window.innerWidth / window.innerHeight;
    
    if (this.camera instanceof THREE.OrthographicCamera) {
      // For orthographic camera, the visible dimensions are affected by zoom
      const visibleWidth = (2 * this.camera.right) * (1 / this.zoomLevel);
      const visibleHeight = (2 * this.camera.top) * (1 / this.zoomLevel);

      // Calculate toolbar widths in world units based on the total visible width
      const leftToolbarWidthWorld = (180 / window.innerWidth) * visibleWidth;
      const rightToolbarWidthWorld = (250 / window.innerWidth) * visibleWidth;

      // Calculate half dimensions
      const halfWidth = visibleWidth / 2;
      const halfHeight = visibleHeight / 2;

      return {
        xMin: this.camera.position.x - halfWidth + leftToolbarWidthWorld,
        xMax: this.camera.position.x + halfWidth - rightToolbarWidthWorld,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight
      };
    } else if (this.camera instanceof THREE.PerspectiveCamera) {
      // For perspective camera, calculate based on FOV and distance
      const distance = Math.abs(this.camera.position.z);
      const halfFov = THREE.MathUtils.degToRad(this.camera.fov / 2);
      const halfHeight = (distance * Math.tan(halfFov)) * (1 / this.zoomLevel);
      const halfWidth = halfHeight * aspectRatio;
      
      // Calculate toolbar widths in world units
      const totalWidth = 2 * halfWidth;
      const leftToolbarWidthWorld = (180 / window.innerWidth) * totalWidth;
      const rightToolbarWidthWorld = (250 / window.innerWidth) * totalWidth;

      return {
        xMin: this.camera.position.x - halfWidth + leftToolbarWidthWorld,
        xMax: this.camera.position.x + halfWidth - rightToolbarWidthWorld,
        yMin: this.camera.position.y - halfHeight,
        yMax: this.camera.position.y + halfHeight
      };
    }

    return null;
  };


  // Helper to calculate the center of the visible area
  getVisibleCenter = () => {
    const visibleBounds = this.getVisibleBounds();
    if (!visibleBounds) return [0, 0];

    const centerX = (visibleBounds.xMin + visibleBounds.xMax) / 2;
    const centerY = (visibleBounds.yMin + visibleBounds.yMax) / 2;
    return [centerX, centerY];
  };


  handleKeyDown = (event: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isInputField =
      activeElement?.id !== "rulerLength" &&
      (activeElement?.tagName === "INPUT" ||
      activeElement?.tagName === "TEXTAREA" ||
      (activeElement?.getAttribute("contenteditable") === "true"));

    // Always handle some keys regardless of input field focus
    if ((this.wallConstructionMode || editorStore.lineConstructionMode || editorStore.rulerConstructionMode || editorStore.areaConstructionMode) && event.key === "Escape") {
      event.preventDefault(); // Prevent any default behavior of the input field
      this.wallConstructionMode = false;
      this.lineConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    } else if (this.wallConstructionMode && event.key === "Enter") {
      this.wallConstructionModeAddNewWall = true
    } else if (this.lineConstructionMode && event.key === "Enter") {
      this.lineConstructionModeAddNewLine = true
    } else if (this.rulerConstructionMode && event.key === "Enter") {
      this.rulerConstructionModeAddNewLine = true
    } 
    else if (this.areaConstructionMode && event.key === "Enter") {
      this.areaConstructionModeAddPoint = true
    }
    // Only handle other shortcuts if not focused on input field
    if (!isInputField) {
      if (event.ctrlKey || event.metaKey) {
        if (event.key === "z") {
          // Ctrl+Z or Cmd+Z
          event.preventDefault();
          this.undo();
        } else if (event.key === "y" || (event.shiftKey && event.key === "z")) {
          // Ctrl+Y or Ctrl+Shift+Z (or Cmd+Shift+Z on macOS)
          event.preventDefault();
          this.redo();
        } else if (event.key === "c") {
          // Ctrl+C or Cmd+C
          event.preventDefault();
          this.copy();
        } else if (event.key === "v") {
          // Ctrl+V or Cmd+V
          event.preventDefault();
          this.paste();
        }
      }

      // Handle zoom in and zoom out with keyboard shortcuts
      if (event.key === "=" || event.key === "+") {
        event.preventDefault();
        this.zoomIn();
      } else if (event.key === "-") {
        event.preventDefault();
        this.zoomOut();
      } else if (event.key === "0") {
        event.preventDefault();
        this.resetZoom();
      }

      // Handle movement with arrow keys
      let movementOffset
      if (event.shiftKey) {
        movementOffset = this.movementOffsetFine;
      } else {
        movementOffset = this.movementOffsetCorse;
      }
      if (event.key === "ArrowUp") {
        event.preventDefault();
        this.moveSelections(0, movementOffset); // Move up
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        this.moveSelections(0, -movementOffset); // Move down
      } else if (event.key === "ArrowLeft") {
        event.preventDefault();
        this.moveSelections(-movementOffset, 0); // Move left
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        this.moveSelections(movementOffset, 0); // Move right
      }

      if (event.key === 'Shift') this.setShiftPressed(true);  

      // Construction mode shortcuts
      if (event.key === "w") {
        this.wallConstructionMode = !this.wallConstructionMode;
      } else if (event.key === "l") {
        this.lineConstructionMode = !this.lineConstructionMode;
      }
      else if (event.key === "r") {
        this.rulerConstructionMode = !this.rulerConstructionMode;
      }
      else if (event.key === "a") {
        this.areaConstructionMode = !this.areaConstructionMode;
      }
    }
  };

  handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') this.setShiftPressed(false);
  }

  // Handle mouse wheel/trackpad zoom events
  handleWheel = (event: WheelEvent) => {
    if (this.camera && !this.view3D) {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);

      if (delta > 0) {
        this.zoomOut();
      } else if (delta < 0) {
        this.zoomIn();
      }
    }
  };

  handleMouseDown = (event: MouseEvent) => {
    // Check if the third mouse button (middle mouse button) is pressed
    if (event.button === 1) {
      // Store the current panning state
      this.previousPanningState = this.panning;
      // Enable panning
      this.setPanning(true);
    }
  };

  handleMouseUp = (event: MouseEvent) => {
  };

  setWallConstructionMode = (mode: boolean) => {
    this.wallConstructionMode = mode;
    if (mode) {
      this.lineConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    }
  }

  setWallConstructionModeAddNewWall = (mode: boolean) => {
    this.wallConstructionModeAddNewWall = mode;
  }

  setLineConstructionMode = (mode: boolean) => {
    this.lineConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.rulerConstructionMode = false;
      this.areaConstructionMode = false;
    }
  }

  setLineConstructionModeAddNewLine = (mode: boolean) => {
    this.lineConstructionModeAddNewLine = mode;
  }
  setRulerConstructionMode = (mode: boolean) => {
    this.rulerConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.lineConstructionMode = false;
      this.areaConstructionMode = false;
      
    }
  }

  setRulerConstructionModeAddNewLine = (mode: boolean) => {
    this.rulerConstructionModeAddNewLine = mode;
  }

  setAreaConstructionMode = (mode: boolean) => {
    this.areaConstructionMode = mode;
    if (mode) {
      this.wallConstructionMode = false;
      this.rulerConstructionMode = false;
      this.lineConstructionMode = false;
    }
  }

  setAreaConstructionModeAddPoint = (mode: boolean) => {
    this.areaConstructionModeAddPoint = mode;
  }

  setWallEditingOuterLength = (wallId: string | undefined) => {
    this.wallEditingOuterLength = wallId
  }
  setWallEditingInnerLength = (wallId: string | undefined) => {
    this.wallEditingInnerLength = wallId
  }

  setWallEditingLengthPanel = (outerLength: number | undefined) => {
    this.wallEditingLengthPanel = outerLength
  }

  setShowMeasures = (show: boolean) => {
    this.showMeasures = show;
  }
  setTextEditing = (text: string | undefined) => {
    this.textEditing = text
  }

  setSingleLineEditingLength = (length: string | undefined) => {
    this.singleLineEditingLength = length
  }

  setSingleLineEditingLengthPanel = (lineLength: number | undefined) => {
    this.singleLineEditingLengthPanel = lineLength
  }

  setRulerLineEditingLength = (length: string | undefined) => {
    this.rulerLineEditingLength = length
  }
  setAreaEditingLength = (length: string | undefined) => {
    this.areaEditingLength = length
  }

  setRulerEditingLengthPanel = (rulerLength: number | undefined) => {
    this.rulerEditingLengthPanel = rulerLength
  }

  setTextShow = (show: boolean) => {
    this.textShow = show;
  }

  setSelections = (selections: Array<WallType | SymbolType>) => {
    // Loop through selections and select them in the floorplanner
    floorplannerStore.unSelectAll();
    selections.forEach((selection) => {
      switch (selection.type) {
        case "wall":
          floorplannerStore.selectWall(selection.id);
          break;
        case "symbol":
        case "door":
        case "doubleDoor":
        case "window":
        case "circleStairs":
        case "rectStairs":
        case "square":
        case "circle":
        case "triangle":
        case "svg":
        case "text":
          floorplannerStore.selectSymbol(selection.id);
          break;
        default:
          console.error("Unknown selection type", selection);
      }
    });
    this.selections = selections;
  };

  clearSelections = () => {
    floorplannerStore.unSelectAll();
    this.selections = [];
  };

  addSelection = (selection: WallType | SymbolType | SingleLineType | RulerLineType) => {
    if (this.selections.findIndex((s) => s.id === selection.id) === -1) {
      this.selections.push(selection);
    }
  };

  removeSelection = (selection: WallType | SymbolType | RulerLineType) => {
    const index = this.selections.findIndex((s) => s.id === selection.id);
    if (index > -1) {
      this.selections.splice(index, 1);
    }
  };

  // Method to move selected elements by a given offset
  moveSelections = (xOffset: number, yOffset: number) => {
    transaction(() => {
      this.selections.forEach((selection) => {
        if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
          const object = floorplannerStore.findObjectId(selection.id);
          if (!object) return;
          // Move the wall by updating its positions
          const start = new THREE.Vector2(object.start.x + xOffset, object.start.y + yOffset);
          const end = new THREE.Vector2(object.end.x + xOffset, object.end.y + yOffset);
          const controlPoint = object.controlPoint ? new THREE.Vector2(object.controlPoint.x + xOffset, object.controlPoint.y + yOffset) : undefined;
          floorplannerStore.updateObjectPosition(object.id, start, end);
          floorplannerStore.setObjectProperty(object.id, "controlPoint", controlPoint);
          updateWallConnections(object.id, "middleV", [object.id], floorplannerStore);

        } else if (isSymbolType(selection)) {
          // Move the symbol by updating its position
          const symbol = this.floorplannerStore.symbolsMap.get(selection.id);
          if (symbol) {
            const posX = symbol.position.x + xOffset * 2;
            const posY = symbol.position.y + yOffset * 2;
            this.floorplannerStore.updateSymbolProperty(selection.id, "position", [
              posX,
              posY,
            ]);
            // If symbol is attached to a wall, detach and re-attach it
            if (symbol.attachedTo) {
              const objectId = symbol.attachedTo;
              this.floorplannerStore.detachSymbolFromWall(selection.id);
              this.floorplannerStore.attachSymbolToWall(selection.id, objectId, [posX, posY]);
            }
          }
        }
      });
    });

    invalidate(); // Trigger re-render to reflect the changes
  };

  setView3D = (view3D: boolean) => {
    this.view3D = view3D;
  };

  setShowGrid = (showGrid: boolean) => {
    this.showGrid = showGrid;
  };

  setMinimized = (minimized: boolean) => {
    this.minimized = minimized;
  };

  // to delete the selection with Backspace
  delete = () => {
    this.selections.forEach((selection) => {
      if (selection.type === "wall") {
        this.floorplannerStore.removeWall(selection.id);
      } else if (isSymbolType(selection)) {
        this.floorplannerStore.removeSymbol(selection.id);
      } else if (isSingleLineType(selection)) {
        this.floorplannerStore.removeSingleLine(selection.id);
      } else if (isRulerLineType(selection)) {
        this.floorplannerStore.removeRulerLine(selection.id);
      }
    });
    this.clearSelections();
  };

  updateGroupRef = (id: string, groupRef: THREE.Group | null) => {
    this.groupRefsMap.set(id, groupRef);
  }

  setWallDragging = (dragging: string | null) => {
    this.wallDragging = dragging;
  }

  setLineDragging = (dragging: string | null) => {
    this.lineDragging = dragging;
  }

  setRulerDragging = (dragging: string | null) => {
    this.rulerDragging = dragging;
  }

  setSnappingTolerance = (tolerance: number) => {
    this.snappingTolerance = tolerance;
  }

  setWhichEndToUpdate = (end: "start" | "end" | undefined) => {
    this.whichEndToUpdate = end;
  }

  openAddToPresentationPopup = () => {
    this.addToPresentationVisible = true;
  }
  closeAddToPresentationPopup = () => {
    this.addToPresentationVisible = false;
  }

  //Download project
  openDownloadProjectPopup = () => {
    this.downloadProjectVisible = true;
  };
  closeDownloadProjectPopup = () => {
    this.downloadProjectVisible = false;
  };

  private getSymbolDimensions(symbol: SymbolType): { width: number, height: number } {
    if (isWindowType(symbol)) {
      // windowLength is the width (along the wall), windowWidth is the height (thickness)
      return { width: symbol.windowLength, height: symbol.windowWidth };
    } else if (isDoorType(symbol)) {
      return { width: symbol.doorWidth, height: symbol.doorWidth };
    } else if (isDoubleDoorType(symbol)) {
      // doubleDoorWidth represents the width of one leaf
      return { width: symbol.doubleDoorWidth, height: symbol.doubleDoorWidth };
    } else if (isCircleStairsType(symbol)) {
      return { width: symbol.circleStairsWidth, height: symbol.circleStairsWidth };
    } else if (isRectStairsType(symbol)) {
      return { width: symbol.rectStairsWidth, height: symbol.rectStairsHeight };
    } else if (isSquareSymbolType(symbol)) {
      return { width: symbol.squareWidth, height: symbol.squareHeight };
    } else if (isTriangleSymbolType(symbol)) {
      return { width: symbol.triangleWidth, height: symbol.triangleHeight };
    } else if (isCircleSymbolType(symbol)) {
      return { width: symbol.circleWidth, height: symbol.circleHeight };
    } else if (isSvgType(symbol)) {
      return { width: symbol.svgLength, height: symbol.svgHeight };
    }
    // Default size for unknown types
    return { width: 1, height: 1 };
  }

  // Helper method to calculate bounds of clipboard items
  private calculateClipboardBounds(items: Array<any>): { min: THREE.Vector2, max: THREE.Vector2 } {
    // First calculate the raw bounds
    const bounds = {
      min: new THREE.Vector2(Infinity, Infinity),
      max: new THREE.Vector2(-Infinity, -Infinity)
    };

    // Calculate the center of all items
    const center = new THREE.Vector2(0, 0);
    let itemCount = 0;

    items.forEach(item => {
      if (isWallType(item) || isSingleLineType(item) || isRulerLineType(item)) {
        // For lines and walls, use the midpoint
        const midpoint = new THREE.Vector2(
          (item.start.x + item.end.x) / 2,
          (item.start.y + item.end.y) / 2
        );
        center.add(midpoint);
        itemCount++;

        bounds.min.x = Math.min(bounds.min.x, item.start.x, item.end.x);
        bounds.min.y = Math.min(bounds.min.y, item.start.y, item.end.y);
        bounds.max.x = Math.max(bounds.max.x, item.start.x, item.end.x);
        bounds.max.y = Math.max(bounds.max.y, item.start.y, item.end.y);
      } else if (isSymbolType(item)) {
        // For symbols, use their position
        center.add(item.position);
        itemCount++;

        const dimensions = this.getSymbolDimensions(item);
        const halfWidth = dimensions.width / 2;
        const halfHeight = dimensions.height / 2;
        
        // Create points relative to center (0,0)
        let points = [
          new THREE.Vector2(-halfWidth, -halfHeight),
          new THREE.Vector2(halfWidth, -halfHeight),
          new THREE.Vector2(halfWidth, halfHeight),
          new THREE.Vector2(-halfWidth, halfHeight)
        ];
        
        // Apply rotation around center (0,0)
        const rotation = item.rotation || 0;
        points = points.map(point => point.rotateAround(new THREE.Vector2(0, 0), rotation));

        // Translate points to item's position
        points = points.map(point => point.add(item.position));

        // Update bounds
        points.forEach(point => {
          bounds.min.x = Math.min(bounds.min.x, point.x);
          bounds.min.y = Math.min(bounds.min.y, point.y);
          bounds.max.x = Math.max(bounds.max.x, point.x);
          bounds.max.y = Math.max(bounds.max.y, point.y);
        });
      }
    });

    // Calculate the true center of all items
    if (itemCount > 0) {
      center.divideScalar(itemCount);
    }

    // Calculate dimensions relative to the center
    const width = bounds.max.x - bounds.min.x;
    const height = bounds.max.y - bounds.min.y;

    // Return bounds centered around the content's true center
    return {
      min: new THREE.Vector2(center.x - width / 2, center.y - height / 2),
      max: new THREE.Vector2(center.x + width / 2, center.y + height / 2)
    };
  }

  /**
   * Finds an open area to paste clipboard contents
   * 
   * Algorithm Overview:
   * 1. Initial Setup:
   *    - Calculates the bounding box of clipboard contents
   *    - Collects bounds of all existing objects (walls, lines, symbols)
   * 
   * 2. Search Strategy:
   *    - Starts from the visible center
   *    - Tries positions in a spiral pattern until finding an open spot
   * 
   * @param clipboardBounds The bounds of the clipboard contents to be pasted
   * @returns The position where the clipboard contents should be pasted
   */
  private findOpenArea(clipboardBounds: { min: THREE.Vector2, max: THREE.Vector2 }): THREE.Vector2 {
    // Get the visible bounds
    const visibleBounds = this.getVisibleBounds();
    if (!visibleBounds) {
      return new THREE.Vector2(0, 0);
    }

    // Calculate clipboard dimensions
    const clipboardWidth = clipboardBounds.max.x - clipboardBounds.min.x;
    const clipboardHeight = clipboardBounds.max.y - clipboardBounds.min.y;

    // Get all existing objects' bounds
    const existingBounds: { min: THREE.Vector2, max: THREE.Vector2 }[] = [];

    // Collect bounds from all objects
    floorplannerStore.wallsMap.forEach(wall => {
      const bounds = this.calculateWallBounds(wall);
      existingBounds.push(bounds);
    });

    floorplannerStore.symbolsMap.forEach(symbol => {
      const bounds = this.calculateSymbolBounds(symbol);
      existingBounds.push(bounds);
    });

    floorplannerStore.singleLinesMap.forEach(line => {
      const bounds = {
        min: new THREE.Vector2(Math.min(line.start.x, line.end.x), Math.min(line.start.y, line.end.y)),
        max: new THREE.Vector2(Math.max(line.start.x, line.end.x), Math.max(line.start.y, line.end.y))
      };
      existingBounds.push(bounds);
    });

    floorplannerStore.rulerLinesMap.forEach(line => {
      const bounds = {
        min: new THREE.Vector2(Math.min(line.start.x, line.end.x), Math.min(line.start.y, line.end.y)),
        max: new THREE.Vector2(Math.max(line.start.x, line.end.x), Math.max(line.start.y, line.end.y))
      };
      existingBounds.push(bounds);
    });

    // Draw the view bounds last and make it more visible
    if (this.debugPasteVisualization && renderStore.scene) {
      // Visualize the current view bounds with margins
      const viewBoundsLine = this.createDebugLine(
        [
          visibleBounds.xMin, visibleBounds.yMin, 0,
          visibleBounds.xMax, visibleBounds.yMin, 0,
          visibleBounds.xMax, visibleBounds.yMax, 0,
          visibleBounds.xMin, visibleBounds.yMax, 0,
          visibleBounds.xMin, visibleBounds.yMin, 0
        ],
        0x000080, // Dark blue color
        1.0, // Full opacity
        3 // Thicker line
      );
      viewBoundsLine.userData.isDebugLine = true;
      renderStore.scene.add(viewBoundsLine);
    }

    // Function to check if a position is valid
    const isValidPosition = (pos: THREE.Vector2): boolean => {
      const testBounds = {
        min: new THREE.Vector2(
          pos.x - clipboardWidth / 2,
          pos.y - clipboardHeight / 2
        ),
        max: new THREE.Vector2(
          pos.x + clipboardWidth / 2,
          pos.y + clipboardHeight / 2
        )
      };

      // Check for overlaps with existing objects
      for (const bound of existingBounds) {
        const gap = 0.2; // Gap between objects
        if (!(
          testBounds.max.x + gap <= bound.min.x ||
          testBounds.min.x - gap >= bound.max.x ||
          testBounds.max.y + gap <= bound.min.y ||
          testBounds.min.y - gap >= bound.max.y
        )) {
          return false;
        }
      }

      return true;
    };

    // Get the original position
    const originalPos = new THREE.Vector2(
      clipboardBounds.min.x,
      clipboardBounds.min.y
    );

    // First try the original position
    if (isValidPosition(originalPos)) {
      this.lastPastePosition = originalPos.clone();
      
      // After finding a valid position, check if we need to pan the camera
      if (visibleBounds) {
        const pasteAreaBounds = {
          xMin: originalPos.x - clipboardWidth / 2,
          xMax: originalPos.x + clipboardWidth / 2,
          yMin: originalPos.y - clipboardHeight / 2,
          yMax: originalPos.y + clipboardHeight / 2
        };
        
        const isOutsideView = 
          pasteAreaBounds.xMin < visibleBounds.xMin ||
          pasteAreaBounds.xMax > visibleBounds.xMax ||
          pasteAreaBounds.yMin < visibleBounds.yMin ||
          pasteAreaBounds.yMax > visibleBounds.yMax;

        if (isOutsideView && this.camera) {
          const centerX = (pasteAreaBounds.xMin + pasteAreaBounds.xMax) / 2;
          const centerY = (pasteAreaBounds.yMin + pasteAreaBounds.yMax) / 2;
          this.camera.position.x = centerX;
          this.camera.position.y = centerY;
          invalidate();
        }
      }
      
      return originalPos;
    }

    // Search in expanding squares around the original position
    const maxAttempts = 1000;
    let attempts = 0;
    let radius = Math.max(clipboardWidth, clipboardHeight) * 0.5; // Start with half the clipboard size

    while (attempts < maxAttempts) {
      // Try positions in a square pattern at current radius
      const positions = [
        // Top edge
        ...Array.from({ length: 8 }, (_, i) => new THREE.Vector2(
          originalPos.x - radius + (radius * 2 * i / 7),
          originalPos.y + radius
        )),
        // Right edge
        ...Array.from({ length: 8 }, (_, i) => new THREE.Vector2(
          originalPos.x + radius,
          originalPos.y + radius - (radius * 2 * i / 7)
        )),
        // Bottom edge
        ...Array.from({ length: 8 }, (_, i) => new THREE.Vector2(
          originalPos.x + radius - (radius * 2 * i / 7),
          originalPos.y - radius
        )),
        // Left edge
        ...Array.from({ length: 8 }, (_, i) => new THREE.Vector2(
          originalPos.x - radius,
          originalPos.y - radius + (radius * 2 * i / 7)
        ))
      ];

      // Check all positions in current square
      for (const pos of positions) {
        if (isValidPosition(pos)) {
          this.lastPastePosition = pos.clone();
          
          // After finding a valid position, check if we need to pan the camera
          if (visibleBounds) {
            const pasteAreaBounds = {
              xMin: pos.x - clipboardWidth / 2,
              xMax: pos.x + clipboardWidth / 2,
              yMin: pos.y - clipboardHeight / 2,
              yMax: pos.y + clipboardHeight / 2
            };
            
            const isOutsideView = 
              pasteAreaBounds.xMin < visibleBounds.xMin ||
              pasteAreaBounds.xMax > visibleBounds.xMax ||
              pasteAreaBounds.yMin < visibleBounds.yMin ||
              pasteAreaBounds.yMax > visibleBounds.yMax;

            if (isOutsideView && this.camera) {
              const centerX = (pasteAreaBounds.xMin + pasteAreaBounds.xMax) / 2;
              const centerY = (pasteAreaBounds.yMin + pasteAreaBounds.yMax) / 2;
              this.camera.position.x = centerX;
              this.camera.position.y = centerY;
              invalidate();
            }
          }
          
          return pos;
        }
        attempts++;
        if (attempts >= maxAttempts) break;
      }

      // Increase radius for next iteration
      radius += Math.max(clipboardWidth, clipboardHeight) * 0.5;
    }

    // If no position found after max attempts, return position slightly offset from original
    const fallbackPos = new THREE.Vector2(
      originalPos.x + clipboardWidth * 0.2,
      originalPos.y + clipboardHeight * 0.2
    );
    this.lastPastePosition = fallbackPos.clone();
    
    // Check if we need to pan for the fallback position
    if (visibleBounds) {
      const pasteAreaBounds = {
        xMin: fallbackPos.x - clipboardWidth / 2,
        xMax: fallbackPos.x + clipboardWidth / 2,
        yMin: fallbackPos.y - clipboardHeight / 2,
        yMax: fallbackPos.y + clipboardHeight / 2
      };
      
      const isOutsideView = 
        pasteAreaBounds.xMin < visibleBounds.xMin ||
        pasteAreaBounds.xMax > visibleBounds.xMax ||
        pasteAreaBounds.yMin < visibleBounds.yMin ||
        pasteAreaBounds.yMax > visibleBounds.yMax;

      if (isOutsideView && this.camera) {
        const centerX = (pasteAreaBounds.xMin + pasteAreaBounds.xMax) / 2;
        const centerY = (pasteAreaBounds.yMin + pasteAreaBounds.yMax) / 2;
        this.camera.position.x = centerX;
        this.camera.position.y = centerY;
        invalidate();
      }
    }
    
    return fallbackPos;
  }

  private visualizeDebugBounds(bounds: { min: THREE.Vector2, max: THREE.Vector2 }, color: number) {
    if (!this.debugPasteVisualization || !renderStore.scene) return;

    const points = [
      bounds.min.x, bounds.min.y, 0,
      bounds.max.x, bounds.min.y, 0,
      bounds.max.x, bounds.max.y, 0,
      bounds.min.x, bounds.max.y, 0,
      bounds.min.x, bounds.min.y, 0
    ];
    const line = this.createDebugLine(points, color);
    line.userData.isDebugLine = true;
    renderStore.scene.add(line);
  }

  private createDebugLine(points: number[], color: number, opacity: number = 0.8, linewidth: number = 2): THREE.Line {
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(points), 3));
    const material = new THREE.LineBasicMaterial({ 
      color: color,
      transparent: true,
      opacity: opacity,
      depthTest: false,
      linewidth: linewidth
    });
    return new THREE.Line(geometry, material);
  }

  // Helper to calculate exact bounds for a wall
  private calculateWallBounds(wall: WallType): { min: THREE.Vector2, max: THREE.Vector2 } {
    // Get the exact wall width without any margin
    const wallWidth = wall.wallWidth || floorplannerStore.wallWidth;
    const halfWidth = wallWidth / 2;
    
    // Calculate wall direction and perpendicular vector
    const wallDirection = new THREE.Vector2()
      .subVectors(wall.end, wall.start)
      .normalize();
    const perpendicular = new THREE.Vector2(-wallDirection.y, wallDirection.x)
      .multiplyScalar(halfWidth);
    
    // Calculate the four corners of the wall
    const corners = [
      new THREE.Vector2().addVectors(wall.start, perpendicular),
      new THREE.Vector2().subVectors(wall.start, perpendicular),
      new THREE.Vector2().addVectors(wall.end, perpendicular),
      new THREE.Vector2().subVectors(wall.end, perpendicular)
    ];
    
    // Find the min/max coordinates
    return {
      min: new THREE.Vector2(
        Math.min(...corners.map(c => c.x)),
        Math.min(...corners.map(c => c.y))
      ),
      max: new THREE.Vector2(
        Math.max(...corners.map(c => c.x)),
        Math.max(...corners.map(c => c.y))
      )
    };
  }

  // Helper to calculate exact bounds for a symbol
  private calculateSymbolBounds(symbol: SymbolType): { min: THREE.Vector2, max: THREE.Vector2 } {
    const dimensions = this.getSymbolDimensions(symbol);
    let points: THREE.Vector2[] = [];

    if (isDoorType(symbol) || isDoubleDoorType(symbol)) {
      const width = dimensions.width;
      const height = dimensions.height;
      const openAngle = symbol.openAngle || Math.PI / 2;
      const flipX = symbol.flipHorizontal ? -1 : 1;
      const flipY = symbol.flipVertical ? -1 : 1;
      
      // Create points for the door frame relative to origin (0,0)
      const doorWidth = isDoubleDoorType(symbol) ? width : width/2;
      points = [
        new THREE.Vector2(0, 0),
        new THREE.Vector2(doorWidth, 0),
        new THREE.Vector2(doorWidth, height),
        new THREE.Vector2(0, height)
      ];

      // For double doors, add swing arc points
      if (isDoubleDoorType(symbol)) {
        const segments = 16;
        // Left door swing
        for (let i = 0; i <= segments; i++) {
          const angle = (i / segments) * openAngle;
          points.push(new THREE.Vector2(
            doorWidth/2 * Math.cos(angle),
            height * Math.sin(angle) * flipY
          ));
        }
        // Right door swing
        for (let i = 0; i <= segments; i++) {
          const angle = (i / segments) * openAngle;
          points.push(new THREE.Vector2(
            doorWidth * 1.5 + doorWidth/2 * Math.cos(-angle),
            height * Math.sin(angle) * flipY
          ));
        }
      }

      // Apply rotation around bottom-left corner (0,0)
      const rotation = symbol.rotation || 0;
      points = points.map(point => point.rotateAround(new THREE.Vector2(0, 0), rotation));

      // Finally translate all points to the symbol's position
      points = points.map(point => point.add(symbol.position));
    } else if (isWindowType(symbol)) {
      // For windows, calculate points relative to center
      const halfWidth = dimensions.width / 2;
      const halfHeight = dimensions.height / 2;
      
      // Create points relative to center (0,0)
      points = [
        new THREE.Vector2(-halfWidth, -halfHeight),
        new THREE.Vector2(halfWidth, -halfHeight),
        new THREE.Vector2(halfWidth, halfHeight),
        new THREE.Vector2(-halfWidth, halfHeight)
      ];
      
      // Apply rotation around center (0,0)
      const rotation = symbol.rotation || 0;
      points = points.map(point => point.rotateAround(new THREE.Vector2(0, 0), rotation));

      // Finally translate all points to the symbol's position
      points = points.map(point => point.add(symbol.position));
    } else {
      // For other symbols, calculate points relative to center
      const halfWidth = dimensions.width / 2;
      const halfHeight = dimensions.height / 2;
      
      // Create points relative to origin (0,0)
      points = [
        new THREE.Vector2(-halfWidth, -halfHeight),
        new THREE.Vector2(halfWidth, -halfHeight),
        new THREE.Vector2(halfWidth, halfHeight),
        new THREE.Vector2(-halfWidth, halfHeight)
      ];
      
      // Apply rotation around origin (0,0)
      const rotation = symbol.rotation || 0;
      points = points.map(point => point.rotateAround(new THREE.Vector2(0, 0), rotation));

      // Finally translate all points to the symbol's position
      points = points.map(point => point.add(symbol.position));
    }
    
    // Calculate bounds from points
    return {
      min: new THREE.Vector2(
        Math.min(...points.map(p => p.x)),
        Math.min(...points.map(p => p.y))
      ),
      max: new THREE.Vector2(
        Math.max(...points.map(p => p.x)),
        Math.max(...points.map(p => p.y))
      )
    };
  }

  private doLinesIntersect(start1: THREE.Vector2, end1: THREE.Vector2, start2: THREE.Vector2, end2: THREE.Vector2, gap: number): boolean {
    // First do a quick bounding box check
    const bounds1 = {
      min: new THREE.Vector2(Math.min(start1.x, end1.x) - gap, Math.min(start1.y, end1.y) - gap),
      max: new THREE.Vector2(Math.max(start1.x, end1.x) + gap, Math.max(start1.y, end1.y) + gap)
    };
    
    const bounds2 = {
      min: new THREE.Vector2(Math.min(start2.x, end2.x) - gap, Math.min(start2.y, end2.y) - gap),
      max: new THREE.Vector2(Math.max(start2.x, end2.x) + gap, Math.max(start2.y, end2.y) + gap)
    };

    // If bounding boxes don't overlap, lines can't intersect
    if (bounds1.max.x < bounds2.min.x || bounds1.min.x > bounds2.max.x ||
        bounds1.max.y < bounds2.min.y || bounds1.min.y > bounds2.max.y) {
      return false;
    }

    // Calculate line vectors
    const v1 = end1.clone().sub(start1);
    const v2 = end2.clone().sub(start2);
    const v3 = start2.clone().sub(start1);

    // Calculate cross products
    const cross1 = v1.x * v3.y - v1.y * v3.x;
    const cross2 = v1.x * v2.y - v1.y * v2.x;

    if (Math.abs(cross2) < 1e-10) {
      // Lines are parallel, check overlap
      const dot = v1.dot(v2);
      const len1Squared = v1.lengthSq();
      const t0 = v1.dot(v3) / len1Squared;
      const t1 = t0 + (dot / len1Squared);
      
      return Math.max(0, Math.min(1, t0), Math.min(1, t1)) <= 
             Math.min(1, Math.max(0, t0), Math.max(0, t1));
    }

    // Calculate intersection parameters
    const t = cross1 / cross2;
    const s = (v2.x * v3.y - v2.y * v3.x) / cross2;

    // Check if intersection point lies within both line segments
    return (t >= -gap && t <= 1 + gap && s >= -gap && s <= 1 + gap);
  }

  // Function to check if a position is valid
  private isValidPosition(pos: THREE.Vector2, clipboardBounds: { min: THREE.Vector2, max: THREE.Vector2 }, existingBounds: Array<{ min: THREE.Vector2, max: THREE.Vector2 }>): boolean {
    const clipboardWidth = clipboardBounds.max.x - clipboardBounds.min.x;
    const clipboardHeight = clipboardBounds.max.y - clipboardBounds.min.y;

    // Calculate test bounds relative to the paste position (which is the top-left corner)
    const testBounds = {
      min: new THREE.Vector2(pos.x, pos.y),
      max: new THREE.Vector2(pos.x + clipboardWidth, pos.y + clipboardHeight)
    };

    // Debug visualization
    if (this.debugPasteVisualization) {
      this.visualizeDebugBounds(testBounds, 0x444444); // Dark grey for test position
    }

    // Calculate offset for the pasted items
    const offset = pos.clone().sub(clipboardBounds.min);

    // First check clipboard walls/lines against existing walls/lines
    const pastedWallsAndLines = this.clipboard.filter(item => 
      isWallType(item) || isSingleLineType(item) || isRulerLineType(item)
    );

    // Check against all existing walls and lines
    for (const item of pastedWallsAndLines) {
      const pastedStart = new THREE.Vector2(item.start.x + offset.x, item.start.y + offset.y);
      const pastedEnd = new THREE.Vector2(item.end.x + offset.x, item.end.y + offset.y);

      // Check against walls
      for (const wall of floorplannerStore.wallsMap.values()) {
        const gap = (wall.wallWidth || floorplannerStore.wallWidth) / 2 + 0.2;
        if (this.doLinesIntersect(pastedStart, pastedEnd, wall.start, wall.end, gap)) {
          return false;
        }
      }

      // Check against single lines
      for (const line of floorplannerStore.singleLinesMap.values()) {
        if (this.doLinesIntersect(pastedStart, pastedEnd, line.start, line.end, 0.2)) {
          return false;
        }
      }

      // Check against ruler lines
      for (const line of floorplannerStore.rulerLinesMap.values()) {
        if (this.doLinesIntersect(pastedStart, pastedEnd, line.start, line.end, 0.2)) {
          return false;
        }
      }
    }

    // Then check clipboard symbols against everything using bounding boxes
    const pastedSymbols = this.clipboard.filter(item => isSymbolType(item));
    for (const symbol of pastedSymbols) {
      const symbolBounds = this.calculateSymbolBounds({
        ...symbol,
        position: new THREE.Vector2(symbol.position.x + offset.x, symbol.position.y + offset.y)
      });

      // Check against all existing bounds
      for (const bound of existingBounds) {
        const gap = 0.2;
        if (!(
          symbolBounds.max.x + gap <= bound.min.x ||
          symbolBounds.min.x - gap >= bound.max.x ||
          symbolBounds.max.y + gap <= bound.min.y ||
          symbolBounds.min.y - gap >= bound.max.y
        )) {
          return false;
        }
      }
    }

    return true;
  }

}

export const editorStore = new EditorStore();
export const editorStoreContext = React.createContext<EditorStore>(editorStore);
export const useEditorStore = () => useContext(editorStoreContext);
