import { useRef, useCallback } from 'react';
import * as THREE from "three";
import { ThreeEvent } from "@react-three/fiber";
import { projectToWorld } from '../components/FloorPlan/projectToWorld';
import { editorStore } from '../store/editorStore';
import { floorplannerStore } from '../store/floorplannerStore';
import { 
    isRulerLineType, 
    isSingleLineType, 
    isSymbolType, 
    isWallType,
    WallType,
    SymbolType,
    SingleLineType,
    RulerLineType
} from '../types/wallTypes';
import { runInAction } from 'mobx';
import { symbol } from 'prop-types';
import { renderStore } from '../store/renderStore';

interface SymbolPositionData {
    id: string;
    type: string;
    position: { x: number; y: number };
}

interface LinePositionData {
    id: string;
    type: string;
    start: { x: number; y: number };
    end: { x: number; y: number };
}

type PositionData = SymbolPositionData | LinePositionData;

interface DragState {
    originalPositions: Map<string, { position?: THREE.Vector2, start?: THREE.Vector2, end?: THREE.Vector2 }>;
    currentOffset: THREE.Vector2;
    lastUpdateTime: number;
    isDragging: boolean;
}

/**
 * Hook to drag selected objects
 * @param gl The WebGLRenderer
 * @param camera The camera
 * @returns The startDragging function and isDragging boolean
 * @example
 * const { startDragging, isDragging } = useDragSelectedObjects(gl, camera);
 * <Canvas onPointerDown={startDragging} />
 * if (isDragging.current) {
 *    // Do something while dragging
 * }
 */
export const useDragSelectedObjects = (gl: THREE.WebGLRenderer, camera: THREE.Camera) => {
    const initialPointerPosition = useRef<[number, number] | null>(null);
    const dragState = useRef<DragState>({
        originalPositions: new Map(),
        currentOffset: new THREE.Vector2(),
        lastUpdateTime: 0,
        isDragging: false
    });
    const isDraggingGroupSelection = useRef(false);
    const aboutToDrag = useRef(true);
    const rafId = useRef<number | null>(null);

    // Throttled update function using requestAnimationFrame
    const updatePositions = useCallback((deltaX: number, deltaY: number) => {
        if (rafId.current) return;

        rafId.current = requestAnimationFrame(() => {
            runInAction(() => {
                editorStore.selections.forEach((selection) => {
                    const originalPos = dragState.current.originalPositions.get(selection.id);
                    if (!originalPos) return;

                    if (isSymbolType(selection) && originalPos.position) {
                        floorplannerStore.updateSymbolProperty(selection.id, "position", [
                            originalPos.position.x + deltaX,
                            originalPos.position.y + deltaY,
                        ]);
                    } else if ((isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) && originalPos.start && originalPos.end) {
                        floorplannerStore.updateObjectPosition(selection.id,
                            new THREE.Vector2(
                                originalPos.start.x + deltaX,
                                originalPos.start.y + deltaY
                            ),
                            new THREE.Vector2(
                                originalPos.end.x + deltaX,
                                originalPos.end.y + deltaY
                            )
                        );
                    }
                });
            });
            rafId.current = null;
        });
    }, []);

    const startDraggingGroupSelection = useCallback((event: ThreeEvent<PointerEvent>) => {
        event.stopPropagation();
        floorplannerStore.pushToUndoStack();

        const [initialWorldX, initialWorldY] = projectToWorld(
            event.clientX,
            event.clientY,
            gl,
            camera
        );

        initialPointerPosition.current = [initialWorldX, initialWorldY];

        dragState.current.originalPositions.clear();
        dragState.current.currentOffset.set(0, 0);
        dragState.current.lastUpdateTime = performance.now();
        dragState.current.isDragging = true;

        // Store original positions
        editorStore.selections.forEach((selection) => {
            if (isSymbolType(selection)) {
                const symbol = floorplannerStore.symbolsMap.get(selection.id);
                if (symbol) {
                    dragState.current.originalPositions.set(selection.id, {
                        position: symbol.position.clone()
                    });
                }
            } else if (isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) {
                const object = floorplannerStore.findObjectId(selection.id);
                if (object) {
                    dragState.current.originalPositions.set(selection.id, {
                        start: object.start.clone(),
                        end: object.end.clone()
                    });
                    // Clear wall clippings when dragging starts
                    if (isWallType(selection)) {
                        renderStore.clearClippings(selection.id);
                    }
                }
            }
        });

        // Disable expensive operations during drag and set dragging state
        floorplannerStore.setBlockDirty(true);
        renderStore.setIsDragging(true);

        const onPointerMove = (moveEvent: PointerEvent) => {
            if (aboutToDrag.current) {
                aboutToDrag.current = false;
            }

            const [currentWorldX, currentWorldY] = projectToWorld(
                moveEvent.clientX,
                moveEvent.clientY,
                gl,
                camera
            );

            const deltaX = currentWorldX - initialPointerPosition.current![0];
            const deltaY = currentWorldY - initialPointerPosition.current![1];
            dragState.current.currentOffset.set(deltaX, deltaY);

            if (!isDraggingGroupSelection.current && (Math.abs(deltaX) > 0.01 || Math.abs(deltaY) > 0.01)) {
                isDraggingGroupSelection.current = true;
            }

            updatePositions(deltaX, deltaY);
        }

        const onPointerUp = () => {
            if (rafId.current) {
                cancelAnimationFrame(rafId.current);
                rafId.current = null;
            }

            dragState.current.isDragging = false;

            // Re-enable updates and reset dragging state
            floorplannerStore.setBlockDirty(false);
            renderStore.setIsDragging(false);

            runInAction(() => {
                // Update final positions
                editorStore.selections.forEach((selection) => {
                    const originalPos = dragState.current.originalPositions.get(selection.id);
                    if (!originalPos) return;

                    if (isSymbolType(selection)) {
                        const symbol = floorplannerStore.symbolsMap.get(selection.id);
                        if (!symbol) return;

                        const attachedWall = floorplannerStore.symbolIsAttached(selection.id);
                        if (attachedWall) {
                            floorplannerStore.detachSymbolFromWall(symbol.id);
                        }

                        if (originalPos.position) {
                            floorplannerStore.updateSymbolProperty(selection.id, "position", [
                                originalPos.position.x + dragState.current.currentOffset.x,
                                originalPos.position.y + dragState.current.currentOffset.y,
                            ]);
                        }

                        if (attachedWall) {
                            floorplannerStore.attachSymbolToWall(selection.id, attachedWall, [symbol.position.x, symbol.position.y]);
                        }
                    } else if ((isWallType(selection) || isSingleLineType(selection) || isRulerLineType(selection)) && originalPos.start && originalPos.end) {
                        floorplannerStore.updateObjectPosition(selection.id,
                            new THREE.Vector2(
                                originalPos.start.x + dragState.current.currentOffset.x,
                                originalPos.start.y + dragState.current.currentOffset.y
                            ),
                            new THREE.Vector2(
                                originalPos.end.x + dragState.current.currentOffset.x,
                                originalPos.end.y + dragState.current.currentOffset.y
                            )
                        );
                        floorplannerStore.markObjectForRoomUpdate(selection.id);
                    }
                });

                // Process room updates and mark as dirty
                floorplannerStore.processRoomUpdates();
                floorplannerStore.setDirty();
            });

            window.removeEventListener("pointermove", onPointerMove);
            window.removeEventListener("pointerup", onPointerUp);
            isDraggingGroupSelection.current = false;
            aboutToDrag.current = true;
        };

        window.addEventListener("pointermove", onPointerMove);
        window.addEventListener("pointerup", onPointerUp);
    }, [gl, camera, updatePositions]);

    const onDragStart = useCallback(() => {
        renderStore.setIsDragging(true);
        renderStore.setBlockDirty(true);
    }, [renderStore]);

    const onDragEnd = useCallback(() => {
        renderStore.setIsDragging(false);
        renderStore.setBlockDirty(false);
        renderStore.setDirty();
    }, [renderStore]);

    return { startDraggingGroupSelection, isDraggingGroupSelection };
};
