// BackdropImage component handles rendering and managing a backdrop image in a 3D scene
// It supports dragging, scaling, and proper texture management with cleanup
import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { observer } from 'mobx-react-lite';
import { floorplannerStore } from '../../store/floorplannerStore';
import { editorStore } from '../../store/editorStore';
import DraggableObject from './DraggableObject';
import { SelectableSymbol, BoundingBox } from './SelectableSymbol';
import { SymbolType, BackdropImageType } from '../../types/wallTypes';
import { Html } from '@react-three/drei';
import { invalidate } from '@react-three/fiber';

// Props interface defines required properties for the backdrop image
// symbol: Contains backdrop-specific properties like size and ratio
// imagePath: URL/path to the image to be displayed
// position: 2D coordinates where the backdrop should be rendered
interface BackdropImageProps {
  symbol: BackdropImageType;
  imagePath: string;
  position: THREE.Vector2;
}

export const BackdropImage: React.FC<BackdropImageProps> = observer(({
  imagePath,
  position,
  symbol,
}) => {
  // State management for the backdrop
  const [texture, setTexture] = useState<THREE.Texture | null>(null);  // Holds the loaded texture
  const meshRef = useRef<THREE.Mesh>(null);  // Reference to the mesh for direct manipulation
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });  // Physical dimensions in scene units
  const [isDragging, setIsDragging] = useState(false);  // Tracks drag state
  const [doubleClickTimer, setDoubleClickTimer] = useState<NodeJS.Timeout | null>(null);  // For double-click detection

  // Calculates the corners and dimensions of the backdrop for selection and interaction
  const calculateBoundingBox = (): BoundingBox => {
    const halfWidth = dimensions.width / 2;
    const halfHeight = dimensions.height / 2;
    
    return {
      topLeft: [position.x - halfWidth, position.y + halfHeight],
      topRight: [position.x + halfWidth, position.y + halfHeight],
      bottomLeft: [position.x - halfWidth, position.y - halfHeight],
      bottomRight: [position.x + halfWidth, position.y - halfHeight],
      width: dimensions.width,
      height: dimensions.height,
      depth: 0
    };
  };

  // Drag handlers to update position in the scene
  const handleDragStart = (offset: [number, number]) => {
    // Only allow dragging if the backdrop is not locked
    if (symbol.locked) return;
    setIsDragging(true);
  };

  const handleDrag = (newPosition: [number, number]) => {
    // Only allow dragging if the backdrop is not locked
    if (symbol.locked) return;
    floorplannerStore.updateSymbolPosition(symbol.id, new THREE.Vector2(newPosition[0], newPosition[1]));
  };

  const handleDragEnd = (endPosition: [number, number]) => {
    setIsDragging(false);
  };

  // Handle single and double clicks on the backdrop
  const handleClick = (event: any) => {
    // If the backdrop is locked, we need double-click to select and we must stop propagation
    if (symbol.locked) {
      // Prevent event from bubbling up to parent components
      event.stopPropagation();
      
      if (doubleClickTimer) {
        // This is a double click - select the backdrop
        clearTimeout(doubleClickTimer);
        setDoubleClickTimer(null);
        selectBackdrop();
      } else {
        // This might be a single click or the first click of a double click
        const timer = setTimeout(() => {
          // It was a single click, do nothing for locked backdrop
          setDoubleClickTimer(null);
        }, 300); // 300ms is a common double-click time
        setDoubleClickTimer(timer);
      }
    } else {
      // For unlocked backdrops, single click is enough to select
      selectBackdrop();
    }
  };

  // Select the backdrop and show it in the PropertyPanel
  const selectBackdrop = () => {
    // Clear existing selections synchronously
    editorStore.clearSelections();
    
    // Update the symbol property synchronously
    floorplannerStore.updateSymbolProperty(symbol.id, "selected", true);
    
    // Add to selection synchronously
    editorStore.addSelection(symbol);
    
    // Force immediate render update
    invalidate();
  };

  // Main effect for loading and managing the backdrop image
  useEffect(() => {
    if (!imagePath) {
      console.error('No backdrop image path provided');
      return;
    }

    const image = new Image();
    let isActive = true;  // Used to prevent updates after unmount

    // Handler for when the image loads successfully
    image.onload = () => {
      if (!isActive) return;

      // Get the natural dimensions of the loaded image
      const originalWidth = image.width;
      const originalHeight = image.height;
      const aspectRatio = originalWidth / originalHeight;
      
      // Calculate real-world dimensions based on backdrop properties
      // backdropSize: measurement in millimeters (e.g., 1000mm = 1m)
      // backdropRatio: what portion of the image width that measurement represents
      // Example: if size=1000mm and ratio=0.5, the full image width is 2000mm (2m)
      const safeSize = Math.max(symbol.backdropSize ?? 10, 0.1);  // in millimeters
      const safeRatio = Math.max(symbol.backdropRatio ?? 1, 0.0001);

      // Calculate final dimensions in scene units (meters)
      const fullWidthMm = safeSize / safeRatio;
      const planeWidth = fullWidthMm / 1000;  // Convert mm to meters
      const planeHeight = planeWidth / aspectRatio;  // Maintain aspect ratio

      // Update the dimensions state for the plane geometry
      setDimensions({
        width: planeWidth,
        height: planeHeight,
      });

      // Create texture directly from the loaded image
      const texture = new THREE.Texture(image);
      texture.needsUpdate = true;
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;

      setTexture(texture);
    };

    // Handle image loading errors
    image.onerror = (error) => {
      console.error('Error loading image:', error);
    };

    // Set crossOrigin to allow loading from different domains
    image.crossOrigin = 'anonymous';
    
    // Start loading the image
    image.src = imagePath;

    // Cleanup function to prevent memory leaks
    return () => {
      isActive = false;
      if (texture) {
        texture.dispose();
      }
      if (doubleClickTimer) {
        clearTimeout(doubleClickTimer);
      }
    };
  }, [imagePath, symbol.backdropSize, symbol.backdropRatio]);

  // Don't render anything until texture is loaded
  if (!texture) {
    return null;
  }

  // Render the backdrop as a draggable, selectable textured plane
  return (
    <>
      {!symbol.locked ? (
        // For unlocked backdrops, use normal DraggableObject and SelectableSymbol
        <DraggableObject
          position={[position.x, position.y]}
          onDragStart={handleDragStart}
          onDrag={handleDrag}
          onDragEnd={handleDragEnd}
          selectable={true}
          symbol={symbol}  // Pass the symbol to DraggableObject
        >
          <SelectableSymbol
            handleSize={floorplannerStore.symbolHandleSize}
            calculateBoundingBox={calculateBoundingBox}
            onResize={() => {}}
            onResizeStart={() => {}}
            onResizeEnd={() => {}}
            showHandles={false} // Never show resize handles on backdrop images
            drawHandles={false} // Never draw handles for backdrop images
            lockAspectRatio={true} // Lock aspect ratio
            symbol={symbol}
          >
            <mesh 
              ref={meshRef}
              position={[0, 0, -0.01]}  // Place slightly behind other elements
              renderOrder={-1000}    // Ensure it renders behind other objects
              onClick={handleClick}
            >
              <planeGeometry args={[dimensions.width, dimensions.height]} />
              <meshBasicMaterial 
                map={texture} 
                transparent={true}    // Enable transparency
                side={THREE.DoubleSide}  // Visible from both sides
                depthTest={true}    // Always render regardless of depth
                depthWrite={false}   // Don't write to depth buffer
                opacity={0.8}
                toneMapped={false}   // Disable tone mapping for consistent colors
              />
            </mesh>
            
            {/* Blue outline when selected */}
            {symbol.selected && (
              <mesh position={[0, 0, -0.005]} renderOrder={-999}>
                <planeGeometry args={[dimensions.width + 0.05, dimensions.height + 0.05]} />
                <meshBasicMaterial 
                  color="blue"
                  transparent={true}
                  opacity={0.3}
                  side={THREE.DoubleSide}
                  depthTest={true}
                  depthWrite={false}
                />
              </mesh>
            )}
          </SelectableSymbol>
        </DraggableObject>
      ) : (
        // For locked backdrops, render as a completely non-interactive mesh
        // No onClick, no onPointerDown, no raycasting - completely disabled
        <group>
          <primitive
            object={new THREE.Object3D()}
            position={[position.x, position.y, -0.01]}
          >
            <mesh 
              renderOrder={-1000}
              ref={meshRef}
              onDoubleClick={(e) => {
                e.stopPropagation();
                selectBackdrop();
              }}
            >
              <planeGeometry args={[dimensions.width, dimensions.height]} />
              <meshBasicMaterial 
                map={texture} 
                transparent={true}
                side={THREE.DoubleSide}
                depthTest={true}
                depthWrite={false}
                opacity={0.8}
                toneMapped={false}
              />
            </mesh>
          </primitive>
        </group>
      )}
    </>
  );
});

// Export a function to handle backdrop deselection for use in other components
export const deselectBackdrop = (id: string) => {
  // Get the complete symbol object from the store
  const symbol = floorplannerStore.symbolsMap.get(id);
  if (symbol) {
    editorStore.removeSelection(symbol);
    floorplannerStore.updateSymbolProperty(id, "selected", false);
    invalidate();
  }
};
